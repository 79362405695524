<template>
  <el-card id="space_card1">
    <div
      style="
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
        height: 50px;
        align-items: center;
      "
    >
      <span
        style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8)"
        >{{ $t("home.myChangeRequest") }}</span
      >
      <el-select
        v-model="now_p"
        filterable
        :placeholder="$t('placeholder.dropdown')"
        size="small"
        @change="chang_p"
      >
        <el-option
          v-for="item in all_p"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>

    <el-table
      :data="now_data"
      style="width: 100%; margin: 0 10px"
      :show-header="false"
      height="200"
      class="outstanding_dialog_table_class"
      @row-click="rowClicked"
      :cell-style="changeCellStyle"
    >
      <el-table-column width="50" style="text-overflow: initial; height: 25px">
        <template slot-scope="scope">
          <span class="icon">
            <personal-avatar
              v-if="scope.row.createdBy"
              :avatar="user_list[scope.row.createdBy].avatar"
              :colorid="scope.row.createdBy"
              :nickname="user_list[scope.row.createdBy].nickname"
              :size="25"
              :tool-tip-disabled="false"
            ></personal-avatar>
          </span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="id" width="50" align="center">
        <template slot-scope="scope">
          <div style="text-overflow: initial; height: 25px">
            <i
              v-if="get_icon(scope.row) !== '-1'"
              class="iconfont"
              :class="'icon-a-' + get_icon(scope.row) + ''"
              style="font-size: 25px; color: rgb(48, 100, 143)"
            >
            </i>
            <span
              v-else
              style="font-weight: 700; color: rgb(38, 50, 129); font-size: 20px"
              >{{
                getFileTypeName(scope.row.fileTypeId, get_icon(scope.row))
              }}</span
            >
          </div>
        </template>
      </el-table-column> -->
      <el-table-column prop="key" width="150">
        <template slot-scope="scope">
          <em>{{ scope.row.nodeKey }}</em>
        </template>
      </el-table-column>
      <el-table-column prop="name" show-overflow-tooltip> </el-table-column>

      <el-table-column prop="nodeName" show-overflow-tooltip> </el-table-column>
    </el-table>

    <div class="foot">
      <div
        style="
          display: flex;
          justify-content: flex-end;
          padding-right: 20px;
          height: 50px;
          align-items: center;
        "
      >
        <el-pagination
          :current-page.sync="currentPage"
          background
          :page-size="size"
          :pager-count="5"
          layout="total, prev, pager, next, jumper"
          :total="data[now_p] ? data[now_p].length : 0"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </el-card>
</template>

<script>
import { changeRequests } from "@/network/workstation/index.js";
import { request_joinedProjects } from "@/network/personal/index.js";
import { get_tenant_user_list } from "@/network/user/index.js";
import PersonalAvatar from "@/components/personal";
import { mapGetters } from "vuex";
export default {
  components: {
    PersonalAvatar,
  },
  props: {
    icondata: {
      type: Object,
    },
  },
  data() {
    return {
      currentPage: 1,
      icon: {},
      total_elements: 30,
      card_list: [],
      isShowTooltip: false,
      all_p: [],
      p: {},
      now_p: "",
      data: {},
      now_data: [],
      avatar: {},
      size: 8,
    };
  },
  computed: {
    ...mapGetters(["user_list"]),
  },
  watch: {
    icondata: {
      immediate: true,
      handler(val) {
        this.icon = val;
      },
    },
  },
  created() {
    const params = {
      pageNumber: 0,
      pageSize: 999999,
    };
    request_joinedProjects().then((res) => {
      this.get_file();
      res.forEach((e) => {
        this.p[e.projectId] = e.projectName;
      });
    });
  },
  methods: {
    changeCellStyle({ row, column, rowIndex, columnIndex }) {
      return "cursor:pointer;";
    },
    visibilityChange(event) {
      const ev = event.target;
      const ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
      const content_weight = ev.clientWidth; // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
      if (ev_weight > content_weight) {
        // 实际宽度 > 可视宽度  文字溢出
        this.isShowTooltip = true;
      } else {
        // 否则为不溢出
        this.isShowTooltip = false;
      }
    },
    get_file() {
      changeRequests().then((res) => {
        if (res.projectChangeRequestDtoList) {
          for (let i of res.projectChangeRequestDtoList) {
            this.all_p.push({ value: i.projectId, name: this.p[i.projectId] });
            this.data[i.projectId] = i.nodeChangeRequestDtoList;
          }
          if (this.all_p && this.all_p.length > 0) {
            this.now_p = this.all_p[0].value;
            this.now_data =
              this.data[this.now_p].length > this.size
                ? this.data[this.now_p].slice(0, this.size)
                : this.data[this.now_p];
          }
        }
      });
    },
    chang_p() {
      this.currentPage = 1;
      this.handleCurrentChange();
    },
    handleCurrentChange() {
      this.now_data = JSON.parse(JSON.stringify(this.data[this.now_p]));
      this.now_data.splice(0, this.size * (this.currentPage - 1));
      this.now_data =
        this.now_data.length > this.size
          ? this.now_data.slice(0, this.size)
          : this.now_data;
    },
    rowClicked(row) {
      const { href } = this.$router.resolve({
        path: `/${row.projectId}/nodes/key/${row.nodeKey}`,
        query: {
          changeRequests: row.changeRequestId,
        },
      });
      window.open(href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  // background-color: rgb(220, 229, 236);
  // border: 1px solid rgb(48, 100, 143);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-table tbody tr:hover > td {
  background-color: transparent !important;
}
em {
  font-size: 18px;
  font-weight: 700;
  color: rgba(255, 195, 0, 1);
  font-style: normal;
  padding-left: 20px;
}
.item {
  // display: flex;
  display: grid;
  grid-template-columns: 50px 150px auto auto;
  justify-items: start;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
#space_card {
  .el-card__body {
    height: 300px;
    padding: 0;
    grid-template-rows: 12.5% 77.5% 10% !important;
  }
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #568cb8;
}
.line {
  height: 1px;
  width: 100%;
  background-color: rgba(204, 204, 204, 1);
}
</style>
