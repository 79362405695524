<template>
  <div style="background-color: rgb(240, 242, 245); height: 100vh">
    <home-topbar
      class="topbar"
      :is-home="false"
      :is-personal="false"
      :is-notification="false"
      :is-worksapce="true"
      @dialoghover="false"
    ></home-topbar>
    <div id="move">
      <h2 style="text-align: left">
        <i
          class="iconfont icon-a-Simba_Fill_MyData1"
          style="color: rgba(7, 83, 174, 1); margin-right: 3px"
        ></i
        >{{ $t("home.personalDataSummary") }}
      </h2>
      <el-scrollbar style="height: 82vh; width: 100%">
        <!--使用draggable组件-->
        <draggable
          v-model="workspacearray"
          chosen-class="chosen"
          group="people"
          animation="300"
          @start="onStart"
          @end="onEnd"
        >
          <transition-group class="space_list_group">
            <div
              v-for="element in workspacearray"
              :key="element.id"
              class="item space_list_group_child"
            >
              <assignee
                v-if="element.name == 'myResponsibility'"
                :icondata="data"
              >
              </assignee>
              <ame v-if="element.name == 'myAt'" :icondata="data"> </ame>
              <review v-if="element.name == 'wait'"></review>
              <changeRequests
                v-if="element.name == 'myChangeRequest'"
              ></changeRequests>
            </div>
          </transition-group>
        </draggable>
        <draggable
          v-model="workspacearray2"
          chosen-class="chosen"
          group="people2"
          animation="300"
          @start="onStart2"
          @end="onEnd2"
        >
          <transition-group class="space_list_group2">
            <div
              v-for="element in workspacearray2"
              :key="element.id"
              class="item space_list_group_child"
            >
              <myfile v-if="element.name == 'myFile'" :icondata="data"></myfile>
              <mynode v-if="element.name == 'myNode'" :icondata="data"></mynode>
              <collection
                v-if="element.name == 'myCollection'"
                :icondata="data"
              ></collection>
            </div>
          </transition-group>
        </draggable>

        <share class="share"></share>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import HomeTopbar from "@/components/homeTopbar";
import draggable from "vuedraggable";
import assignee from "./components/Assignee";
import ame from "./components/Ame";
import myfile from "./components/Myfile";
import mynode from "./components/Mynode";
import collection from "./components/Collection";
import changeRequests from "./components/ChangeRequests";
import review from "./components/Review";
import { get_icon } from "@/network/workstation/index.js";
import { activated } from "@/network/user/index.js";
import share from "./components/share";

import vmson from "@/utils/vmson";

export default {
  components: {
    draggable,
    assignee,
    ame,
    myfile,
    mynode,
    collection,
    changeRequests,
    HomeTopbar,
    review,
    share,
  },
  data() {
    return {
      data: {},
      drag: false,
      //定义要被拖拽对象的数组
      workspacearray: [
        { id: 1, name: "myResponsibility" },
        { id: 2, name: "myAt" },
        { id: 3, name: "wait" },
        { id: 4, name: "myChangeRequest" },
      ],
      workspacearray2: [
        { id: 1, name: "myFile" },
        { id: 2, name: "myNode" },
        { id: 3, name: "myCollection" },
      ],
      // dialogVisible: false,
      // basic_license_time: 0,
    };
  },
  created() {
    activated().then((res) => {});
    get_icon().then((res) => {
      let a = {};
      res.forEach((e) => {
        a[e.fileTypeId] = { icon: e.icon, name: e.name };
      });
      this.data = a;
      vmson.$emit("close_Collapse", "***");
    });
    let workspace_array = localStorage.getItem("newworkspacearray");
    let workspace_array2 = localStorage.getItem("newworkspacearray2");

    if (
      workspace_array &&
      JSON.parse(workspace_array).length == this.workspacearray.length
    ) {
      this.workspacearray = JSON.parse(workspace_array);
    } else {
      sessionStorage.setItem(
        "newworkspacearray",
        JSON.stringify(this.workspacearray)
      );
    }
    if (
      workspace_array2 &&
      JSON.parse(workspace_array2).length == this.workspacearray2.length
    ) {
      this.workspacearray2 = JSON.parse(workspace_array2);
    } else {
      sessionStorage.setItem(
        "newworkspacearray2",
        JSON.stringify(this.workspacearray2)
      );
    }
  },
  mounted() {
    // 获取基础软件到期时间提示
  },
  // computed: {
  //   basic_license_data() {},
  // },
  methods: {
    //开始拖拽事件
    onStart() {
      this.drag = true;
    },
    //拖拽结束事件
    onEnd() {
      this.drag = false;
      sessionStorage.setItem(
        "newworkspacearray",
        JSON.stringify(this.workspacearray)
      );
      localStorage.setItem(
        "newworkspacearray",
        JSON.stringify(this.workspacearray)
      );
    },
    //开始拖拽事件
    onStart2() {
      this.drag = true;
    },
    //拖拽结束事件
    onEnd2() {
      this.drag = false;
      sessionStorage.setItem(
        "newworkspacearray2",
        JSON.stringify(this.workspacearray2)
      );
      localStorage.setItem(
        "newworkspacearray2",
        JSON.stringify(this.workspacearray2)
      );
    },
  },
};
</script>
<style lang="scss">
#move {
  margin: 0 auto;
  width: 90vw;

  .el-scrollbar__wrap {
    overflow-x: hidden !important;
  }

  .space_list_group {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 49% 49%;
    grid-column-gap: 1%;
    margin: 0 10px 0 0;
    .space_list_group_child:nth-child(1) {
      margin-bottom: 25px;
    }
    .space_list_group_child:nth-child(2) {
      margin-bottom: 25px;
    }
  }
  .space_list_group2 {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    grid-column-gap: 1%;
    margin: 25px 10px 0 0;
    justify-content: space-between;
  }
}
</style>
<style scoped>
.topbar {
  height: 60px;
  background-color: rgba(48, 100, 143, 1);
  width: 100vw;
  padding: 0;
}
</style>

<style lang="scss" scoped>
.share {
  margin-top: 25px;
}
</style>
