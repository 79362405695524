<template>
  <span class="status_tag">
    <el-tooltip effect="dark" placement="top" :disabled="!isShowTooltip">
      <div slot="content">{{ value }}</div>
      <span
        :class="{
          status: true,
          status_todo: status_type == 'TODO',
          status_progress: status_type == 'IN_PROGRESS',
          status_open: status_type == 'DONE',
        }"
        @mouseenter="visibilityChange($event)"
      >
        <span style="white-space: nowrap">{{ value }}</span>
      </span>
    </el-tooltip>
    <!-- <el-tag :effect="style_catogry" :class="status_type" :size="size" >
      {{ value }}
    </el-tag> -->
  </span>
</template>

<script>
export default {
  props: {
    style_catogry: {
      type: String,
      default: "plain",
    },
    use: {
      type: Boolean,
      default: true,
    },
    //TODO、IN_PROGRESS、Done
    status_type: {
      type: String,
      default: "TODO",
    },
    value: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "small",
    },
  },
  data() {
    return {
      isShowTooltip: false,
    };
  },
  computed: {},
  mounted() {},

  methods: {
    visibilityChange(event) {
      const ev = event.target.children[0];
      const ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
      const content_weight = ev.clientWidth; // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
      if (ev_weight > content_weight && this.use) {
        // 实际宽度 > 可视宽度  文字溢出
        this.isShowTooltip = true;
      } else {
        // 否则为不溢出
        this.isShowTooltip = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.status_todo {
  background-color: rgba(166, 166, 166, 1);
  border: 1px solid rgba(166, 166, 166, 1);
  color: white;
}
.status_open {
  color: white;
  background-color: rgba(67, 207, 124, 1);
  border: 1px solid rgba(67, 207, 124, 1);
}
.status_progress {
  color: white;
  background-color: rgba(42, 130, 228, 1);
  border: 1px solid rgba(42, 130, 228, 1);
}
// .status_todo {
//   border: 1px solid rgba(166, 166, 166, 1);
//   color: rgba(166, 166, 166, 1);
// }
// .status_open {
//   color: rgba(67, 207, 124, 1);
//   border: 1px solid rgba(67, 207, 124, 1);
// }
// .status_progress {
//   color: rgba(42, 130, 228, 1);
//   border: 1px solid rgba(42, 130, 228, 1);
// }
.status {
  // width: 120px;
  height: 32px;
  border-radius: 4px;
  // color: #fff;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  font-size: 12px;
  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
// .status_tag {
//   .el-tag.TODO{
//     border-color: rgba(166, 166, 166, 1);
//     color: rgba(166, 166, 166, 1);
//     background-color: transparent;
//   }
//   .el-tag.IN_PROGRESS {
//     border-color: #2a82e4;
//     color: #2a82e4;
//     background-color: transparent;
//   }
//   .el-tag.DONE {
//     border-color: #a5d63f;
//     color: #a5d63f;
//     background-color: transparent;
//   }
// }
</style>
