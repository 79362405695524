<template>
  <el-card id="space_card" class="space_card">
    <div
      style="
        display: flex;
        justify-content: space-between;
        padding-left: 20px;
        height: 50px;
        align-items: center;
      "
    >
      <span
        style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8)"
        >{{ $t("home.myAt") }}</span
      >
    </div>
    <div style="padding: 0 20px 0 20px;height: 200px;overflow: auto;">
      <div
        v-for="(obj, i) in card_list"
        :key="i"
        class="item"
        @click="rowClicked(obj)"
      >
        <span class="icon">
          <i
            v-if="get_icon(obj) !== '-1'"
            class="iconfont"
            :class="'icon-a-' + get_icon(obj)"
            style="font-size: 25px; color: rgb(48, 100, 143)"
          ></i>
          <span
            v-else
            style="font-weight: 700; color: rgb(38, 50, 129); font-size: 20px"
            >{{ getFileTypeName(obj.fileTypeId, get_icon(obj)) }}</span
          >
        </span>
        <div class="content">
          <div style="display: flex">
            <span>
              <em>{{ obj.nodeKey }}</em>
            </span>
            <el-tooltip
              effect="dark"
              :content="obj.topic"
              placement="top"
              :disabled="!isShowTooltip"
            >
              <p
                style="
                  padding-left: 20px;
                  margin: 0;
                  width: 500px;
                  text-align: left;
                  font-size: 17px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                "
                @mouseenter="visibilityChange($event)"
              >
                {{ obj.topic }}
              </p>
            </el-tooltip>
          </div>
          <div
            v-if="getlength(getdata(obj.content)) <= 67"
            style="margin-top: 5px"
          >
            <span style="font-weight: 600; color: #555">{{
              get_author(obj.author) + " : "
            }}</span
            >{{ getdata(obj.content) }}
          </div>
          <el-scrollbar
            v-else
            style="
              margin-top: 5px;
              height: 50px;
              overflow-y: auto;
              overflow-x: hidden;
              word-break: break-all;
            "
          >
            <div style="word-break: break-all">
              <span style="font-weight: 600; color: #555">{{
                get_author(obj.author) + " : "
              }}</span>
              {{ getdata(obj.content) }}
            </div>
          </el-scrollbar>
          <br />
          <span style="">{{ obj.sendAt }}</span>
        </div>
      </div>
    </div>
    <div class="foot">
      <div class="line"></div>
      <div
        style="
          display: flex;
          justify-content: flex-end;
          padding-right: 20px;
          height: 50px;
          align-items: center;
        "
      >
        <el-pagination
          :current-page.sync="currentPage"
          background
          :page-size="3"
          :pager-count="5"
          layout="total, prev, pager, next, jumper"
          :total="total_elements"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </el-card>
</template>

<script>
import {
  get_notifications_list,
  seenSingle,
} from "@/network/notification/index.js";
import { get_atMe_list } from "@/network/workstation/index.js";
import { get_tenant_user_list } from "@/network/user/index.js";
import PersonalAvatar from "@/components/personal";
import { mapGetters } from "vuex";
export default {
  components: {
    PersonalAvatar,
  },
  data() {
    return {
      avatar: {},
      all: 10,
      icon: {},
      currentPage: 1,
      total_elements: 0,
      isShowTooltip: false,
      userList: [],
      card_list: [],
    };
  },
  props: {
    icondata: {
      type: Object,
    },
  },
  watch: {
    icondata: {
      immediate: true,
      handler(val) {
        this.icon = val;
      },
    },
  },
  computed: {
    ...mapGetters(["fileType"]),
  },
  created() {
    const params = {
      pageNumber: 0,
      pageSize: 999999,
    };
    get_tenant_user_list(params).then((res) => {
      res.content.forEach((element) => {
        this.avatar[element.accountId] = [element.avatar, element.nickname];
      });
      this.userList = res.content;
      this.handleCurrentChange();
    });
    this.get_file();
  },
  methods: {
    getFileTypeName(id, val) {
      if (val === "-1" && this.icon[id]) {
        return this.icon[id].name.slice(0, 1);
      }
    },
    get_icon(o) {
      return this.icon[o.fileTypeId] ? this.icon[o.fileTypeId].icon : "-1";
    },
    visibilityChange(event) {
      const ev = event.target;
      const ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
      const content_weight = ev.clientWidth; // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
      if (ev_weight > content_weight) {
        // 实际宽度 > 可视宽度  文字溢出
        this.isShowTooltip = true;
      } else {
        // 否则为不溢出
        this.isShowTooltip = false;
      }
    },
    getlength(str) {
      let total = 0,
        len = str.length || 0;
      for (let i = 0; i < len; i++) {
        if (str[i].charCodeAt() > 255) {
          total += 2;
        } else {
          total += 1;
        }
      }
      return total;
    },
    getdata(l) {
      let str = "";
      if (l) {
        try {
          l = JSON.parse(l);
          for (let i of l) {
            if (i.type == "at") {
              str += "@" + i.data.nickname;
            } else {
              str += i.data;
            }
          }
          // eslint-disable-next-line no-empty
        } catch (e) {}
      }
      return str;
    },
    handleCurrentChange() {
      const params = {
        action: "commentAt",
        pageNumber: this.currentPage - 1,
        pageSize: 3,
        workstation: true,
      };
      // get_notifications_list(params).then((res) => {
      //   this.total_elements = res.total;
      //   this.card_list = res.notificationDetailDtoList;
      // });
      this.get_file();
    },
    get_file() {
      get_atMe_list(this.currentPage - 1, 3).then((res) => {
        this.card_list = res.nodeAndCommentList;
        this.total_elements = res.count;
      });
    },
    get_author(uid) {
      for (let i of this.userList) {
        if (i["accountId"] == uid) {
          return i["nickname"];
        }
      }
      return "Unknown";
    },
    seenSingle(item) {
      if (!item.topic) {
        this.$message({
          type: "warning",
          message: this.$t("notice.message"),
        });
        return;
      }
      if (!item.seen) {
        seenSingle(item.notificationId).then((res) => {
          item.seen = true;
          const { href } = this.$router.resolve({
            name: "node_detail",
            params: {
              projectId: item.projectId,
              nodeKey: item.nodeKey,
            },
          });
          window.open(href, "_blank");
        });
      } else {
        const { href } = this.$router.resolve({
          name: "node_detail",
          params: {
            projectId: item.projectId,
            nodeKey: item.nodeKey,
          },
        });
        window.open(href, "_blank");
      }
    },
    rowClicked(row) {
      const { href } = this.$router.resolve({
        path: `/${row.projectId}/nodes/key/${row.nodeKey}`,
      });
      window.open(href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  // background-color: rgb(220, 229, 236);
  width: 50px;
  height: 50px;
  // border: 1px solid rgb(48, 100, 143);
  // border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  scrollbar-3dlight-color: #d4d0c8;
  scrollbar-highlight-color: #fff;
  scrollbar-face-color: #e4e4e4;
  scrollbar-arrow-color: #666;
  scrollbar-shadow-color: #808080;
  scrollbar-darkshadow-color: #d7dce0;
  scrollbar-base-color: #d7dce0;

  text-align: left;
  font-size: 16px;
  em {
    font-size: 18px;
    font-weight: 700;
    color: rgba(255, 195, 0, 1);
    font-style: normal;
  }
  p {
    font-weight: 400;
    color: rgba(56, 56, 56, 1);
  }
}
.item {
  margin-bottom: 15px;
  cursor: pointer;
  display: grid;
  grid-template-columns: 10% 90%;
}
</style>
<style lang="scss" scoped>
/* 解决自定义滚动条 x 轴显示问题 */

#space_card {
  .el-scrollbar__wrap {
    overflow-x: hidden !important;
  }
  .el-card__body {
    display: grid;
    grid-template-rows: 15% 75% 10% !important;
    height: 300px;
    padding: 0;
  }
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #568cb8;
}
.line {
  height: 1px;
  width: 100%;
  background-color: #EBEEF5;
}
</style>
