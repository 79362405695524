<template>
  <el-card id="space_card1">
    <div
      style="
        display: flex;
        justify-content: space-between;
        padding-left: 20px;
        height: 50px;
        align-items: center;
      "
    >
      <span
        style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8)"
        >{{ $t("addNodeDetail.wait") }}</span
      >
    </div>

    <el-table
      :data="card_list"
      style="width: 100%; margin: 0 10px"
      :show-header="false"
      height="200"
      class="outstanding_dialog_table_class"
      @row-click="seenSingle"
      :cell-style="changeCellStyle"
    >
      <el-table-column prop="id" width="50" align="center">
        <template slot-scope="scope">
          <span class="icon">
            <personal-avatar
              :avatar="avatar[scope.row.createdBy][0]"
              :colorid="scope.row.createdBy"
              :nickname="avatar[scope.row.createdBy][1]"
              :size="25"
              :tool-tip-disabled="false"
            ></personal-avatar>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="key" width="150">
        <template slot-scope="scope">
          <span
            :class="{
              status: true,
              status_progress: !scope.row.isHandle,
              status_open: scope.row.isHandle,
            }"
            style="margin: 0 auto"
          >
            <span style="white-space: nowrap">{{
              scope.row.isHandle ? "已完成" : "待评审"
            }}</span>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="title" show-overflow-tooltip> </el-table-column>
    </el-table>

    <div class="foot">
      <div
        style="
          display: flex;
          justify-content: flex-end;
          padding-right: 20px;
          height: 50px;
          align-items: center;
        "
      >
        <el-pagination
          :current-page.sync="currentPage"
          background
          :page-size="size"
          :pager-count="5"
          layout="total, prev, pager, next, jumper"
          :total="total_elements"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </el-card>
</template>

<script>
import { get_review } from "@/network/workstation/index.js";
import { get_file } from "@/network/baseline/index";
import StatusTag from "@/components/statusTag";
import { get_tenant_user_list } from "@/network/user/index.js";
import PersonalAvatar from "@/components/personal";
export default {
  components: {
    PersonalAvatar,
    StatusTag,
  },
  data() {
    return {
      all: 10,
      currentPage: 1,
      total_elements: 30,
      card_list: [],
      isShowTooltip: false,
      icon: {},
      avatar: {},
      review_data: [],
      size: 7,
    };
  },
  created() {
    const params = {
      pageNumber: 0,
      pageSize: 999999,
    };
    get_tenant_user_list(params).then((res) => {
      get_review().then((ress) => {
        this.review_data = ress;
        this.total_elements = ress.length;
        ress.length > this.size
          ? (this.card_list = ress.slice(0, this.size))
          : (this.card_list = ress);
      });
      res.content.forEach((element) => {
        this.avatar[element.accountId] = [element.avatar, element.nickname];
      });
    });
  },
  methods: {
    changeCellStyle({ row, column, rowIndex, columnIndex }) {
      return "cursor:pointer;";
    },
    seenSingle(o) {
      if (o.projectId) {
        get_file(o.projectId, o.fileId).then((result) => {
          //跳转到思维导图
          const { href } = this.$router.resolve({
            path: `/${o.projectId}/myMind/${result.key}`,
            query: { process_review: "process_review" },
          });
          window.open(href, "_blank");
        });
      }
    },
    visibilityChange(event) {
      const ev = event.target;
      const ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
      const content_weight = ev.clientWidth; // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
      if (ev_weight > content_weight) {
        // 实际宽度 > 可视宽度  文字溢出
        this.isShowTooltip = true;
      } else {
        // 否则为不溢出
        this.isShowTooltip = false;
      }
    },
    handleCurrentChange() {
      this.review_data.length > this.size * this.currentPage
        ? (this.card_list = this.review_data.slice(
            this.size * (this.currentPage - 1),
            this.size * this.currentPage
          ))
        : (this.card_list = this.review_data.slice(
            this.size * (this.currentPage - 1),
            this.review_data.length
          ));
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table tbody tr:hover > td {
  background-color: transparent !important;
}
.status_open {
  color: rgba(67, 207, 124, 1);
  border: 1px solid rgba(67, 207, 124, 1);
}
.status_progress {
  color: rgba(42, 130, 228, 1);
  border: 1px solid rgba(42, 130, 228, 1);
}
.status {
  width: 80px;
  height: 32px;
  border-radius: 4px;
  // color: #fff;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.icon {
  // background-color: rgb(220, 229, 236);
  // border: 1px solid rgb(48, 100, 143);
  // border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
em {
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  padding-left: 20px;
}
.item {
  display: grid;
  grid-template-columns: 35px 150px auto;
  justify-items: start;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
}
</style>
<style lang="scss" scoped>
#space_card {
  .el-card__body {
    height: 300px;
    padding: 0;
    // align-items: center;
    grid-template-rows: 12.5% 77.5% 10% !important;
  }
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #568cb8;
}
.line {
  height: 1px;
  width: 100%;
  background-color: rgba(204, 204, 204, 1);
}
</style>
