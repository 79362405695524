<template>
  <el-card id="space_card1">
    <div
      style="
        display: flex;
        justify-content: space-between;
        padding-left: 20px;
        height: 50px;
        align-items: center;
      "
    >
      <span style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8)"
        >{{ $t("home.myCollection") }}
        <i
          class="iconfont icon-shoucangClick"
          style="color: rgb(255, 195, 0)"
        />
      </span>
    </div>
    <el-table
      :data="card_list"
      style="width: 100%; margin: 0 10px"
      height="200"
      :show-header="false"
      class="outstanding_dialog_table_class"
      @row-click="rowClicked"
      :cell-style="changeCellStyle"
    >
      <el-table-column prop="id" width="50" align="center">
        <template slot-scope="scope">
          <div style="text-overflow: initial; height: 25px">
            <i
              v-if="get_icon(scope.row) !== '-1'"
              class="iconfont"
              :class="'icon-a-' + get_icon(scope.row) + ''"
              style="font-size: 25px; color: rgb(48, 100, 143)"
            >
            </i>
            <span
              v-else
              style="font-weight: 700; color: rgb(38, 50, 129); font-size: 20px"
              >{{
                getFileTypeName(scope.row.fileTypeId, get_icon(scope.row))
              }}</span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="key" width="150">
        <template slot-scope="scope">
          <em>{{ scope.row.key }}</em>
        </template>
      </el-table-column>
      <el-table-column prop="name" show-overflow-tooltip> </el-table-column>
    </el-table>

    <div class="foot">
      <div
        style="
          display: flex;
          justify-content: flex-end;
          padding-right: 20px;
          height: 50px;
          align-items: center;
        "
      >
        <el-pagination
          :current-page.sync="currentPage"
          background
          :page-size="size"
          :pager-count="5"
          layout="total, prev, pager, next, jumper"
          :total="total_elements"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
      <!-- <div style="width: 100%;padding:5px;text-align: right;padding-right:20px">
        <el-pagination :current-page.sync="currentPage" background :page-size="7" layout="total,pager" :total="total_elements>35?35:total_elements" @current-change="handleCurrentChange">
        </el-pagination>
      </div> -->
    </div>
  </el-card>
</template>

<script>
import { get_collections } from "@/network/workstation/index.js";
import { mapGetters } from "vuex";

export default {
  props: {
    icondata: {
      type: Object,
    },
  },
  data() {
    return {
      currentPage: 1,
      icon: {},
      total_elements: 30,
      card_list: [],
      isShowTooltip: false,
      size: 8,
    };
  },
  watch: {
    icondata: {
      immediate: true,
      handler(val) {
        this.icon = val;
      },
    },
  },
  computed: {
    ...mapGetters(["fileType"]),
  },
  created() {
    this.get_file();
  },
  methods: {
    changeCellStyle({ row, column, rowIndex, columnIndex }) {
      return "cursor:pointer;";
    },
    getFileTypeName(id, val) {
      if (val === "-1" && this.icon[id]) {
        return this.icon[id].name.slice(0, 1);
      }
    },
    get_icon(o) {
      return this.icon[o.fileTypeId] ? this.icon[o.fileTypeId].icon : "-1";
    },
    visibilityChange(event) {
      const ev = event.target;
      const ev_weight = ev.scrollWidth; // 文本的实际宽度   scrollWidth：对象的实际内容的宽度，不包边线宽度，会随对象中内容超过可视区后而变大。
      const content_weight = ev.clientWidth; // 文本的可视宽度 clientWidth：对象内容的可视区的宽度，不包滚动条等边线，会随对象显示大小的变化而改变。
      if (ev_weight > content_weight) {
        // 实际宽度 > 可视宽度  文字溢出
        this.isShowTooltip = true;
      } else {
        // 否则为不溢出
        this.isShowTooltip = false;
      }
    },
    get_file() {
      get_collections("all", this.currentPage - 1, this.size).then((res) => {
        this.card_list = res.data;
        this.total_elements = res.total;
      });
    },
    handleCurrentChange() {
      this.get_file();
    },
    rowClicked(row) {
      if (row.type == "file") {
        const { href } = this.$router.resolve({
          path: `/${row.projectId}/myMind/${row.key}`,
        });
        window.open(href, "_blank");
      }
      if (row.type == "node") {
        const { href } = this.$router.resolve({
          path: `/${row.projectId}/nodes/key/${row.key}`,
        });
        window.open(href, "_blank");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  // background-color: rgb(220, 229, 236);
  width: 50px;
  height: 50px;
  // border: 1px solid rgb(48, 100, 143);
  // border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-table tbody tr:hover > td {
  background-color: transparent !important;
}
em {
  font-size: 18px;
  font-weight: 700;
  color: rgba(255, 195, 0, 1);
  font-style: normal;
  padding-left: 20px;
}

.item {
  display: grid;
  grid-template-columns: 50px 150px auto;
  justify-items: start;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
}
</style>
<style lang="scss">
#space_card {
  .el-card__body {
    height: 300px;
    padding: 0;
  }
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #568cb8;
}

.line {
  height: 1px;
  width: 100%;
  background-color: rgba(204, 204, 204, 1);
}
</style>
