import request from '@/utils/request'
import serviceConfig from '@/network/serviceConfig'
export function shrae_file(projectId, filekey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/${filekey}/sharing`,
    method: 'post',
  })
}
export function change_fileShareSetting(data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/fileShareSetting`,
    method: 'put',
    data: data,
  })
}
export function get_fileShareSetting() {
  return request({
    url: `/${serviceConfig['file-manage-service']}/fileShareSetting`,
    method: 'get',
  })
}
export function getFile(shareId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/publicResource/sharedFiles/${shareId}`,
    method: 'get',
  })
}
export function get_file_share(projectId, fileKey) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/${fileKey}/sharing/switch`,
    method: 'get',
  })
}
export function save_file(projectId, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/SharingFiles/saving`,
    method: 'post',
    data: data,
  })
}
export function get_status(shareId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/publicResource/sharedFiles/${shareId}/status`,
    method: 'get',
  })
}
export function viewRequestNotify(shareId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/sharedFiles/${shareId}/viewRequestNotify`,
    method: 'post',
  })
}
export function change_file_share(projectId, fileKey, data) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/${projectId}/files/${fileKey}/sharing/switch`,
    method: 'put',
    data: data,

  })
}

export function get_tenant_share(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/sharedFiles/tenant`,
    method: 'get',
    params
  })
}
export function get_mine_share(params) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/sharedFiles/mine`,
    method: 'get',
    params
  })
}

export function downloadword(shareId) {
  return request({
    url: `/${serviceConfig['file-manage-service']}/shareFiles/${shareId}/wordFile`,
    method: 'get',
  })
}