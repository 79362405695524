<template>
  <el-card id="space_card1">
    <div
      style="
        display: flex;
        justify-content: space-between;
        padding-left: 20px;
        height: 50px;
        align-items: center;
      "
    >
      <span
        style="font-size: 18px; font-weight: 600; color: rgba(0, 0, 0, 0.8)"
        >{{ $t("home.myResponsibility") }}</span
      >
      <!-- <span style="font-size: 18px;font-weight: 500;rgba(56, 56, 56, 1)">全部{{ '('+total_elements+')' }}</span> -->
    </div>
    <el-table
      :data="card_list"
      style="width: 100%; margin: 0 10px"
      :show-header="false"
      height="200"
      class="outstanding_dialog_table_class"
      @row-click="rowClicked"
      :cell-style="changeCellStyle"
    >
      <el-table-column prop="id" width="50" align="center">
        <template slot-scope="scope">
          <div style="text-overflow: initial; height: 25px">
            <i
              v-if="get_icon(scope.row) !== '-1'"
              class="iconfont"
              :class="'icon-a-' + get_icon(scope.row) + ''"
              style="font-size: 25px; color: rgb(48, 100, 143)"
            >
            </i>
            <span
              v-else
              style="font-weight: 700; color: rgb(38, 50, 129); font-size: 20px"
              >{{
                getFileTypeName(scope.row.fileTypeId, get_icon(scope.row))
              }}</span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="key" width="150">
        <template slot-scope="scope">
          <em>{{ scope.row.key }}</em>
        </template>
      </el-table-column>
      <el-table-column prop="topic" show-overflow-tooltip> </el-table-column>
    </el-table>

    <div class="foot">
      <div
        style="
          display: flex;
          justify-content: flex-end;
          padding-right: 20px;
          height: 50px;
          align-items: center;
        "
      >
        <el-pagination
          :current-page.sync="currentPage"
          background
          :page-size="size"
          :pager-count="5"
          layout="total, prev, pager, next, jumper"
          :total="total_elements"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </el-card>
</template>

<script>
import {
  get_notifications_list,
  seenSingle,
} from "@/network/notification/index.js";
import { get_assignee_list } from "@/network/workstation/index.js";
import { get_tenant_user_list } from "@/network/user/index.js";
import PersonalAvatar from "@/components/personal";
import { mapGetters } from "vuex";
export default {
  components: {
    PersonalAvatar,
  },
  props: {
    icondata: {
      type: Object,
    },
  },
  data() {
    return {
      all: 10,
      currentPage: 1,
      total_elements: 0,
      size: 8,
      card_list: [],
      isShowTooltip: false,
      icon: {},
      avatar: {},
    };
  },
  computed: {
    ...mapGetters(["userAccountId"]),
    ...mapGetters(["fileType"]),
  },
  watch: {
    icondata: {
      immediate: true,
      handler(val) {
        this.icon = val;
      },
    },
  },
  created() {
    const params = {
      pageNumber: 0,
      pageSize: 999999,
    };
    get_tenant_user_list(params).then((res) => {
      this.handleCurrentChange();
      res.content.forEach((element) => {
        this.avatar[element.accountId] = [element.avatar, element.nickname];
      });
    });
    this.get_file();
  },
  methods: {
    changeCellStyle({ row, column, rowIndex, columnIndex }) {
      return "cursor:pointer;";
    },
    getFileTypeName(id, val) {
      if (val === "-1" && this.icon[id]) {
        return this.icon[id].name.slice(0, 1);
      }
    },
    get_icon(o) {
      return this.icon[o.fileTypeId] ? this.icon[o.fileTypeId].icon : "-1";
    },
    handleCurrentChange() {
      const params = {
        action: "assign",
        pageNumber: this.currentPage - 1,
        pageSize: this.size,
      };
      // get_notifications_list(params).then((res) => {
      //   this.total_elements = res.total;
      //   this.card_list = res.notificationDetailDtoList;
      // });
      this.get_file();
    },
    get_file() {
      get_assignee_list(this.currentPage - 1, this.size).then((res) => {
        this.card_list = res.nodeList;
        this.total_elements = res.count;
      });
    },
    seenSingle(item) {
      if (!item.topic) {
        this.$message({
          type: "warning",
          message: this.$t("notice.message"),
        });
        return;
      }
      if (!item.seen) {
        seenSingle(item.notificationId).then((res) => {
          item.seen = true;
          const { href } = this.$router.resolve({
            name: "node_detail",
            params: {
              projectId: item.projectId,
              nodeKey: item.nodeKey,
            },
          });
          window.open(href, "_blank");
        });
      } else {
        const { href } = this.$router.resolve({
          name: "node_detail",
          params: {
            projectId: item.projectId,
            nodeKey: item.nodeKey,
          },
        });
        window.open(href, "_blank");
      }
    },
    rowClicked(row) {
      const { href } = this.$router.resolve({
        path: `/${row.projectId}/nodes/key/${row.key}`,
      });
      window.open(href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  // background-color: rgb(220, 229, 236);
  width: 50px;
  height: 50px;
  // border: 1px solid rgb(48, 100, 143);
  // border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
em {
  font-size: 18px;
  font-weight: 700;
  color: rgba(255, 195, 0, 1);
  font-style: normal;
  padding-left: 20px;
}
.item {
  display: grid;
  grid-template-columns: 50px 150px auto;
  justify-items: start;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
}
</style>
<style lang="scss">
.outstanding_dialog_table_class td,
.outstanding_dialog_table_class th.is-leaf {
  border: none;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #568cb8;
}
.line {
  height: 1px;
  width: 100%;
  background-color: rgba(204, 204, 204, 1);
}

#space_card1 {
  height: 300px;
  .el-card__body {
    padding: 0;
    // align-items: center;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table tbody tr:hover > td {
  background-color: transparent !important;
}
</style>
